export { sendResultsLoadedMessage } from './iframe-common';

export async function reviewsfetch(url, params) {
  const fetchGetJSON = {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  };

  for (const param in params) {
    if (Array.isArray(params[param])) {
      params[param].forEach(val => {
        url += `&${param}=${val}`;
      });
    } else {
      url += `&${param}=${params[param]}`;
    }
  }

  try {
    const response = await fetch(url, fetchGetJSON);
    const data = await response.json();
    return data;
  } catch(err) {
    return Promise.reject(err);
  }
}

export function createReviews(container, entityId, count, rating) {
  const reviewsEl = container.querySelector('.js-reviews-summary');
  const starsEl = container.querySelector('.js-reviews-stars');
  const scoreEl = container.querySelector('.js-reviews-score');
  const countEl = container.querySelector('.js-reviews-count');
  let starEmpty = document.createElement('img');
  starEmpty.setAttribute('src', '../../static/assets/images/star-empty.svg');
  starEmpty.setAttribute('alt', '');

  if (count > 0 && rating) {
    const remainder = count % Math.floor(rating);
    for (let i = 0; i < Math.floor(rating); i++) {
      this.appendStar(starsEl, 'full');
    }
    if (remainder > 0.75) {
      this.appendStar(starsEl, 'full');
    } else if (remainder > 0.25) {
      this.appendStar(starsEl, 'half');
    } else if (remainder > 0) {
      this.appendStar(starsEl, 'empty');
    }
    for (let i = Math.ceil(rating); i < 5; i++) {
      this.appendStar(starsEl, 'empty');
    }
    reviewsEl.classList.add('js-reviews-flex');
    scoreEl.innerHTML = rating.toFixed(1);
    reviewsEl.setAttribute('data-rating', rating.toFixed(1));
    reviewsEl.setAttribute('data-count', count);
    countEl.innerHTML = count > 1
      ? '(' + count + ' reviews)'
      : '(1 review)';
  } else {
    reviewsEl.classList.add('js-reviews-flex');
    reviewsEl.classList.add('js-reviews-unavailable');
    countEl.innerHTML = 'Reviews for this healthcare provider are not currently available.';
  }

  return entityId;
}

export function injectReviews(id, reviewsData) {
  const reviewsContainer = document.getElementById("card-" + id);
  if (!reviewsContainer || !reviewsData?.response?.docs) return;
  for (let doc of reviewsData.response.docs) {
    if (doc.entity.id.toString() === id) {
    this.createReviews(reviewsContainer, id, doc.reviewCount, doc.averageRating);
    }
  }
}

export function appendStar(target, type) {
  let star = document.createElement('img');
  star.setAttribute('class', 'HitchhikerProductProminentImage-star')
  star.setAttribute('alt', '');

  if (type == 'full') {
    star.setAttribute('src', '../../static/assets/images/star-full.svg');
  } else if (type == 'half') {
    star.setAttribute('src', '../../static/assets/images/star-half.svg');
  } else if (type == 'empty') {
    star.setAttribute('src', '../../static/assets/images/star-empty.svg');
  }

  target.appendChild(star);
}

// Returns an array of all active filters for percent match
export function getFilters(appliedQueryFilters) {

  let activeFilters = [];

  // Grabs NLP filters from appliedQueryFilter object passed through in transformData()
  if (appliedQueryFilters) {
    for (let [_, filter] of Object.entries(appliedQueryFilters)) {
      if (filter.value && !activeFilters.includes(filter.value)) {
        activeFilters.push(filter.value);
      }
    }
  }

  // Grabs active facet filters from answers core object
  let storage = ANSWERS.core.storage?.storage;
  if (!storage) return activeFilters;

  storage = Object.fromEntries(storage);
  if (!storage.facetFilters) return activeFilters;

  // Unpacking filter nested json structure
  for (let [_, i] of Object.entries(storage.facetFilters)) {
    if (i.length) {
      for (let [_, j] of Object.entries(i)) {
        for (let [_, filter] of Object.entries(j)) {
          if (filter['$eq'] && !activeFilters.includes(filter['$eq'])) {
            activeFilters.push(filter['$eq']);
          }
        }
      }
    }
  }

  return activeFilters;
}

// Sort results based on percent match then by review score
export function compareResults(a, b) {
  const percentA = parseInt(a.querySelector('.HitchhikerProductProminentImage-percentage')?.dataset?.percent || 0);
  const percentB = parseInt(b.querySelector('.HitchhikerProductProminentImage-percentage')?.dataset?.percent || 0);

  if (percentA !== percentB) return percentB - percentA;

  // If percent match is the same, sort by number of reviews
  const reviewsElA = a.querySelector('.js-reviews-summary');
  const reviewsElB = b.querySelector('.js-reviews-summary');
  const ratingCountA = reviewsElA?.dataset?.count ? parseFloat(reviewsElA.dataset.count) : 0;
  const ratingCountB = reviewsElB?.dataset?.count ? parseFloat(reviewsElB.dataset.count) : 0;

  if (ratingCountB !== ratingCountA) return ratingCountB - ratingCountA;

  // If number of reviews is also the same, sort by reviews score
  const ratingA = reviewsElA?.dataset?.rating ? parseFloat(reviewsElA.dataset.rating) : 0;
  const ratingB = reviewsElB?.dataset?.rating ? parseFloat(reviewsElB.dataset.rating) : 0;

  return ratingB - ratingA;
}

// Initializes custom facet logic
// This includes collapsing all facets on mount and only allowing one facet menu open at a time
export function initFacets(el) {
  if (!el) return;
  collapseFilters(el);
  const filterboxes = el.querySelectorAll('.yxt-FilterBox-filter');

  for (const box of filterboxes) {
    // Need to add event listener to filterboxes because facet buttons are re-rendered upon any change
    box.addEventListener('mousedown', (e) => {
      if (e.origin != 'collapseFilters') {
        let filterFieldset = box.querySelector('.yxt-FilterOptions-fieldSet');
        collapseFilters(el, filterFieldset)
      }
    })
  }
}

// Collapses all expanded facet menus except for current one
export function collapseFilters(el, currentFieldset = null) {
  if (!el) return;
  const fieldsets = el.querySelectorAll('.yxt-FilterOptions-fieldSet');
  for (const fieldset of fieldsets) {
    const expandButton = fieldset.querySelector('.yxt-FilterOptions-clickableLegend');
    const menu = fieldset.querySelector('.js-yxt-FilterOptions-container');

    if (!expandButton || !menu) continue;

    // We need to trigger a click event to get the answers state to update correctly in the backend
    // No analytics are associated with expanding facet buttons so this is safe to do
    const clickEvent = new Event('mousedown', {"bubbles": true, "cancelable": false});
    clickEvent.button = 0;
    clickEvent.origin = 'collapseFilters';

    // Add "selectedCount" element even if facet menu is expanded
    const selectedCount = getSelectedCount(fieldset);
    const hasCountEl = expandButton.querySelector('.js-yxt-FilterOptions-selectedCount');
    if (selectedCount && !hasCountEl) {
      const countEl = document.createElement("span");
      countEl.classList.add('yxt-FilterOptions-selectedCount');
      countEl.classList.add('js-yxt-FilterOptions-selectedCount');
      countEl.innerHTML = selectedCount;
      expandButton.appendChild(countEl);
    }

    if (fieldset === currentFieldset) continue;
    
    // If menu is open and is not the current menu, click to close it
    if (!menu.classList.contains('yxt-FilterOptions--collapsed')) {
      expandButton.dispatchEvent(clickEvent);
    }
  }
}

// Grabs the current number of active facets from any facet div
export function getSelectedCount(filterEl) {
  const options = filterEl.querySelectorAll('input');
  return Array.from(options).reduce((numSelected, option) => option.checked ? numSelected + 1 : numSelected, 0);
}
